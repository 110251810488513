<template>
  <LiefengContent>
    <template v-slot:title>申请记录
      <span class="eye" @click.stop="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template v-slot:toolsbarRight>
      <Form :model="searchForm" :inline="true" :label-width="60">
        <FormItem label="姓名">
          <Input
            v-model.trim="searchForm.name"
            :maxlength="10"
            placeholder="请输入姓名"
            style="width: 160px"
          ></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            v-model.trim="searchForm.phone"
            :maxlength="20"
            placeholder="请输入手机号"
            style="width: 160px"
          ></Input>
        </FormItem>
        <FormItem label="状态">
          <Select v-model="searchForm.status" style="width: 160px">
            <Option value="0">全部</Option>
            <Option value="1">待处理资料未完善</Option>
            <Option value="2">待处理资料已完善</Option>
            <Option value="3">处理中</Option>
            <Option value="4">有结果</Option>
            <Option value="5">已过期</Option>
          </Select>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin-right: 10px"
          >搜索</Button
        >
        <Button
          type="warning"
          icon="md-add"
          @click="addModalStatus = true"
          style="margin-right: 10px"
          >新增申请</Button
        >
        <Button type="success" icon="ios-download-outline" @click="exportTable"
          >导出申请列表</Button
        >
      </Form>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
        @tableSelect="tableSelect"
      ></LiefengTable>
      <!-- 新增 -->
      <LiefengModal
        title="评估申请"
        :fullscreen="true"
        :value="addModalStatus"
        @input="addModalStatusFn"
        class="addModal"
      >
        <template v-slot:contentarea>
          <AddApply
            v-if="addModalStatus"
            @saveSuccess="saveSuccess"
            ref="addApply"
          ></AddApply>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              addModalStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button @click="saveAdd" type="primary">保存</Button>
        </template>
      </LiefengModal>
      <!-- 查看 -->
      <LiefengModal
        title="查看评估申请"
        :fullscreen="true"
        :value="seeModalStatus"
        @input="seeModalStatusFn"
      >
        <template v-slot:contentarea>
          <SeeApply v-if="seeModalStatus" :infoId="infoId"></SeeApply>
        </template>
        <template v-slot:toolsbar>
          <Button @click="seeModalStatusFn(false)" type="primary">关闭</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/pensionindex');
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddApply from "@/views/pension/childrens/addApply";
import SeeApply from "@/views/pension/childrens/seeApply";
export default {
  data() {
    return {
      hasSensitive:true,
      searchForm: {},
      pageSize: 20,
      page: 1,
      total: 0,
      loading: false,
      tableData: [],
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "姓名",
          align: "center",
          width: 120,
          key: "name",
        },
        {
          title: "性别",
          align: "center",
          width: 100,
          key: "sex",
        },
        {
          title: "手机号码",
          align: "center",
          width: 130,
          key: "phone",
        },
        {
          title: "出生日期",
          align: "center",
          width: 120,
          key: "birthday",
        },
        {
          title: "年龄",
          align: "center",
          width: 100,
          key: "age",
        },
        {
          title: "所属社区",
          align: "center",
          width: 140,
          key: "orgName",
        },
        {
          title: "申请提交账号",
          align: "center",
          width: 140,
          key: "creator",
        },
        {
          title: "申请时间",
          align: "center",
          minWidth: 170,
          key: "gmtCreate",
        },
        {
          title: "处理时间",
          align: "center",
          minWidth: 170,
          key: "gmtModified",
        },
        {
          title: "状态",
          align: "center",
          width: 160,
          key: "status",
        },
        {
          title: "操作",
          align: "center",
          width: 140,
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.$Message.loading({
                        content: "正在加载数据，请稍等...",
                        duration: 0,
                      });
                      this.infoId = params.row.applyId;
                      this.seeModalStatus = true;
                    },
                  },
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content: "<p>确定删除该申请记录？</p>",
                        onOk: () => {
                          this.$get(
                            "/pension/api/pc/elderly/applyRecord/deleteByApplyId",
                            {
                              applyId: params.row.applyId,
                            }
                          )
                            .then((res) => {
                              if (res.code == 200) {
                                this.$Message.success({
                                  background: true,
                                  content: "删除成功！",
                                });
                                this.hadlePageSize({
                                  page: this.page,
                                  pageSize: this.pageSize,
                                });
                              } else {
                                this.$Message.error({
                                  background: true,
                                  content: res.desc,
                                });
                              }
                            })
                            .catch((err) => {
                              this.$Message.error({
                                background: true,
                                content: "删除失败！",
                              });
                            });
                        },
                      });
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      //   新增
      addModalStatus: false,
      cancelModelTip: true,
      // 查看
      infoId: "",
      seeModalStatus: false,
      // 导出
      exportArr: [],
    };
  },
  methods: {
    // 表格选中
    tableSelect(arr) {
      let exportArr = this.exportArr.filter((item) => {
        //去掉当前页选中的数据
        return item.page != this.page;
      });
      arr.map((item) => {
        exportArr.push({
          ...item,
          page: this.page,
        });
      });
      this.exportArr = exportArr;
    },
    // 导出
    exportTable() {
      if (this.exportArr.length == 0) {
        this.$Message.warning({
          background: true,
          content: "请先选择需导出的申请记录信息",
        });
        return;
      }
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在导出选中的申请信息，是否继续</p>",
        onOk: () => {
          let applyIdArr = [];
          this.exportArr.map((item) => {
            applyIdArr.push({
              applyId: item.applyId,
              status: "3",
            });
          });
          this.$post(
            "/pension/api/pc/elderly/applyRecord/updateStatusInList",
            {
              applyRecordUpdateRoList: applyIdArr,
            },
            { "Content-Type": "application/json" }
          ).then((res) => {
            if (res.code == 200) {
              this.loading = true;
              this.$get(
                "/pension/api/pc/elderly/applyRecord/queryApplyRecordByPage",
                {
                  ...this.searchForm,
                  status:
                    this.searchForm.status == "0" ? "" : this.searchForm.status,
                  page: this.page,
                  pageSize: this.pageSize,
                  custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                  orgCode: parent.vue.loginInfo.userinfo.orgCode,
                }
              )
                .then((res) => {
                  this.loading = false;
                  if (res.code == 200 && res.dataList) {
                    res.dataList.map((item) => {
                      item.gmtModified = item.gmtModified
                        ? this.$core.formatDate(
                            new Date(item.gmtModified),
                            "yyyy-MM-dd hh:mm:ss"
                          )
                        : "";
                      item.gmtCreate = this.$core.formatDate(
                        new Date(item.gmtCreate),
                        "yyyy-MM-dd hh:mm:ss"
                      );
                      item.age = item.birthday
                        ? Math.ceil(
                            (new Date().getTime() -
                              new Date(item.birthday).getTime()) /
                              31536000000
                          )
                        : "";
                      item.sex =
                        item.sex == "2"
                          ? "女"
                          : item.sex == "1"
                          ? "男"
                          : item.sex == "0"
                          ? "未知"
                          : "";
                      item.status =
                        item.status == "1"
                          ? "待处理资料未完善"
                          : item.status == "2"
                          ? "待处理资料已完善"
                          : item.status == "3"
                          ? "处理中"
                          : item.status == "4"
                          ? "有结果"
                          : item.status == "5"
                          ? "已过期"
                          : "";
                    });
                    this.tableData = res.dataList;
                    this.page = res.currentPage;
                    this.pageSize = res.pageSize;
                    this.total = res.maxCount;

                    let tHeader = [
                      "姓名",
                      "性别",
                      "手机号码",
                      "出生日期",
                      "年龄",
                      "所属社区",
                      "申请时间",
                      "处理时间",
                      "状态",
                    ];
                    let filterVal = [
                      "name",
                      "sex",
                      "phone",
                      "birthday",
                      "age",
                      "orgName",
                      "gmtCreate",
                      "gmtModified",
                      "status",
                    ];
                    let exportArr = [];
                    this.exportArr.map((item) => {
                       this.tableData.map(subItem => {
                          if(item.applyId == subItem.applyId) {
                            exportArr.push(subItem)
                          }
                        })
                    });
                    this.$core.exportExcel(
                      tHeader,
                      filterVal,
                      exportArr,
                      "申请记录"
                    );
                    this.exportArr = [];
                  } else {
                    this.$Message.error({
                      background: true,
                      content: res.desc,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                  this.$Message.error({
                    background: true,
                    content: "获取数据失败，请重试",
                  });
                });
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        },
      });
    },
    //   新增
    saveAdd() {
      this.$refs.addApply.openPromiseModal();
    },
    saveSuccess() {
      this.cancelModelTip = false;
      this.addModalStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    addModalStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addModalStatus = status;
          },
        });
      } else {
        this.addModalStatus = status;
      }
    },
    // 查看
    seeModalStatusFn(status) {
      this.seeModalStatus = status;
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/pension/api/pc/elderly/applyRecord/queryApplyRecordByPage", {
        ...this.searchForm,
        status: this.searchForm.status == "0" ? "" : this.searchForm.status,
        page: obj.page,
        pageSize: obj.pageSize,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        hasSensitive:this.hasSensitive
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.gmtModified = item.gmtModified
                ? this.$core.formatDate(
                    new Date(item.gmtModified),
                    "yyyy-MM-dd hh:mm:ss"
                  )
                : "";
              item.gmtCreate = this.$core.formatDate(
                new Date(item.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
              );
              item.age = item.birthday
                ? Math.ceil(
                    (new Date().getTime() - new Date(item.birthday).getTime()) /
                      31536000000
                  )
                : "";
              item.sex =
                item.sex == "2"
                  ? "女"
                  : item.sex == "1"
                  ? "男"
                  : item.sex == "0"
                  ? "未知"
                  : "";
              item.status =
                item.status == "1"
                  ? "待处理资料未完善"
                  : item.status == "2"
                  ? "待处理资料已完善"
                  : item.status == "3"
                  ? "处理中"
                  : item.status == "4"
                  ? "有结果"
                  : item.status == "5"
                  ? "已过期"
                  : "";
            });
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddApply,
    SeeApply,
  },
};
</script>
    
<style scoped lang='less'>
// /deep/#toolsbarRight {
//   width: 1000%;
//   .ivu-form-item {
//     margin-bottom: 0;
//   }
// }
.addModal {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
</style>